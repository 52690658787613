/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "./Pagination";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import * as XLSX from "xlsx";
import { MdMarkEmailRead } from "react-icons/md";

const NewCandidatetable = () => {
  const dispatch = useDispatch();
  const customToastStyle = {
    background: "#14B8A6",
    color: "white",
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)",
    },
    "--toastify-icon-color-success": "white",
  };
  const newcandidate = useSelector((store) => store.newcandidate);
  const token = useSelector((store) => store.token);
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [recordsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);
  const [loadingnew2, setloadingnew2] = useState(false);
  const [selectid, setselectid] = useState(null);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  useEffect(() => {
    // Disable search input initially and enable it after 3 seconds
    const searchTimeout = setTimeout(() => {
      setIsSearchEnabled(true);
    }, 5000);

    return () => clearTimeout(searchTimeout);
  }, []);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = totaldata.filter((skill) => {
        return skill.first_name.toLowerCase().includes(e.toLowerCase());
      });
      setalldata(matchingSkills);
      setCurrentPage(1);
    } else {
      setalldata(totaldata);
    }
  };

  // Custom sorting function
  const statusOrder = {
    "New Candidate": 1,
    "Mail send": 2,
    Success: 3,
    SuccessWithOnboarding: 4,
    Reject: 5,
  };

  const sortCandidates = (a, b) => {
    const getStatusRank = (candidate) => {
      if (candidate.status === "Success" && candidate.onboarding_status > 1) {
        return statusOrder["SuccessWithOnboarding"];
      }
      return statusOrder[candidate.status] || 6;
    };

    const rankA = getStatusRank(a);
    const rankB = getStatusRank(b);

    return rankA - rankB;
  };

  useEffect(() => {
    Getcandidate();
  }, []);

  const Getcandidate = async () => {
    if (newcandidate.length !== 0) {
      setalldata(newcandidate);
      setloading(false);
      settotaldata(newcandidate);
      var allfacility = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility.faculties !== undefined) {
        if (allfacility.faculties.length !== 0) {
          var checkdata = await allfacility.faculties.filter((data) => {
            return (
              (data.status == "New Candidate" ||
                data.status == "Success" ||
                data.status == "Reject" ||
                data.status == "Mail send" ||
                data.status !== "") &&
              data.nottify !== true
            );
          });
          checkdata.sort(sortCandidates);
          setloading(false);
          setalldata(checkdata);
          settotaldata(checkdata);
          dispatch(storeAction.newcandidateHander({ newcandidate: checkdata }));
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      let allfacility1 = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => err.response);

      if (allfacility1.faculties !== undefined) {
        if (allfacility1.faculties.length !== 0) {
          let checkdata1 = await allfacility1.faculties.filter((data) => {
            return (
              data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send" ||
              data.nottify !== true
            );
          });

          checkdata1.sort(sortCandidates);
          setloading(false);
          setalldata(checkdata1);
          settotaldata(checkdata1);
          dispatch(
            storeAction.newcandidateHander({ newcandidate: checkdata1 })
          );
        }
      } else {
        setloading(false);
      }
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = alldata.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(alldata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const acceptbtn = async (data) => {
    setloadingnew(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      subject: "Action Required: Complete Your Application Today!",
      message: `
      <p>Dear ${data.first_name},</p>
      <p>We are delighted to process your application. Please click on the link below to complete your application:</p>
      <p><a href="https://app.hirein5.com/#/createpassword/${data.email}">Complete Your Application</a></p>
      <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send" ||
            data.nottify !== true
          );
        });
        checkdata1.sort(sortCandidates);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newcandidateHander({ newcandidate: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const acceptbtn1 = async (data) => {
    setloadingnew2(true);
    setselectid(data.id);
    var notification = {
      email: data.email,
      subject: "Action Required: Complete Your Application Today!",
      message: `
      <p>Dear ${data.first_name},</p>
      <p>We are delighted to process your application. Please click on the link below to complete your application:</p>
      <p><a href="https://app.hirein5.com/#/createpassword/${data.email}">Complete Your Application</a></p>
      <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send" ||
            data.nottify !== true
          );
        });
        checkdata1.sort(sortCandidates);
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newcandidateHander({ newcandidate: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
    setselectid(null);
  };
  const rejectbtn = async (data) => {
    setloadingnew1(true);
    setselectid(data.id);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      subject: "Application Update!",
      message: `<p>Hi ${data.first_name},</p>
                <p>Thank you for your interest in Hirein5.</p>
                <p>We regret to inform you that we are unable to process your application at this stage. We shall be in touch for other openings.</p>
                <p>All the best,</p>
                <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setselectid(null);
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  const exportbtn = async () => {
    const manipulatedData = alldata.map((item) => {
      if (item.new_candidate_info !== null) {
        return {
          // S_No: index + 1,
          Name: item.first_name,
          Email: item.username,
          Phone_Number: item.phone,
          Skills:
            item.new_candidate_info.skills.length !== 0
              ? item.new_candidate_info.skills.join(", ")
              : " ",
          Resume: item.new_candidate_info.resume,
        };
      }
    });
    var finaldata = manipulatedData.filter((data) => {
      return data !== undefined;
    });
    const worksheet = XLSX.utils.json_to_sheet(finaldata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Onboarding_candidate.xlsx");
  };

  return (
    <div className="adminProfile paddingLeft50 paddingRight50">
      <DashHead
        head="Onboarding Candidate"
        desc="View and update profiles of onboarded candidates"
        descClass="dashBoardMainHeadDescBetween"
      />
      <button className="exportntn" onClick={exportbtn}>
        Export
      </button>
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder={!isSearchEnabled ? "Please wait..." : "Search..."}
            onChange={(e) => searchvalue(e.target.value)}
            disabled={!isSearchEnabled}
          />
          <img src={search} alt="" />
        </div>

        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable1">
            <thead>
              <tr className="AdminTableHead">
                <th>Candidate Name</th>
                <th>Location</th>
                <th>Key Skills</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Resume</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        {data.new_candidate_info !== null ? (
                          <h1>{data.new_candidate_info.location}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.new_candidate_info !== null ? (
                          <h1>{data.new_candidate_info.skills.join(", ")}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>
                      <td>
                        <h1
                          className="font-medium text-blue-500 cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${
                                data.new_candidate_info !== null
                                  ? data.new_candidate_info.resume
                                  : null
                              }`,
                              "_blank"
                            );
                          }}
                        >
                          View
                        </h1>
                      </td>
                      <td>
                        {data.status == "Success" &&
                        data.onboarding_status > 1 ? (
                          <p className="reserving px-2 rounded-full">
                            In Progress
                          </p>
                        ) : data.status == "Success" ? (
                          <p className="hiringActive px-2 rounded-full">
                            Processing
                          </p>
                        ) : data.status == "Reject" ? (
                          <p className="rejectstaus px-2 rounded-full">
                            On Hold
                          </p>
                        ) : data.status == "New Candidate" ? (
                          <p className="contracted px-2 rounded-full">New</p>
                        ) : data.status == "Mail send" ? (
                          <p className="processingstaus px-2 rounded-full">
                            Reviewed
                          </p>
                        ) : (
                          <p className="hiringActive px-2 rounded-full">-</p>
                        )}
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {data.status == "Success" ||
                          data.status == "Mail send" ? null : loadingnew ==
                            false ? (
                            <button
                              className="acceptbtn"
                              onClick={() => {
                                acceptbtn(data);
                              }}
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            selectid == data.id && (
                              <button className="acceptbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {loadingnew1 === false ? (
                            <>
                              <button
                                className="rejectbtn"
                                onClick={() => {
                                  rejectbtn(data);
                                }}
                              >
                                X
                              </button>
                              {loadingnew2 == true && selectid == data.id ? (
                                <button className="accept_btn">
                                  <FiLoader className="loadingIcon" />
                                </button>
                              ) : data.status == "New Candidate" ? null : (
                                <button
                                  className="accept_btn"
                                  onClick={() => {
                                    acceptbtn1(data);
                                  }}
                                >
                                  <MdMarkEmailRead />
                                </button>
                              )}
                            </>
                          ) : (
                            selectid == data.id && (
                              <button className="rejectbtn">
                                <FiLoader className="loadingIcon" />
                              </button>
                            )
                          )}
                          {/* <button
                            className="acceptbtn"
                            onClick={() => {
                              acceptbtn(data);
                            }}
                          >
                            <FaCheck />
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewCandidatetable;
