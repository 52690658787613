import React from "react";
import BillingComp from "../Components/Client/ClientScreen/BillingComp/BillingComp";
import Billinginfo from "../Components/NewClientFlow/Billinginfo";

const Billing = () => {
  return (
    <div>
      <BillingComp />
      <Billinginfo />
    </div>
  );
};

export default Billing;
